import React from 'react'

function Offcanvas() {
  return (
   <>
    <button class="btn btn-primary" >Toggle right offcanvas</button>

   
   </>
  )
}

export default Offcanvas
