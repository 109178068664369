import React from 'react'

function Signout() {
  return (
    <>
    <div className='wrapper'>
   <div className='form-wrapper'>
          
         <div className='submit mt-5'>
            <button className='mt-5'>Sign Out</button>
         </div>
</div>
</div>
</>
  )
}

export default Signout
